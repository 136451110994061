/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { KTIcon } from '../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import MoveFolderModal from '../MyDocuments/Modals/MoveFolderModal';
import FolderSuccessModal from '../MyDocuments/Modals/FolderSuccessModal';
import jsPDF from 'jspdf';
import { useAuth } from '../../modules/auth';
import { marked } from "marked";

import MyResearchButtons from './MyResearchButtons';
import { set } from 'date-fns';

interface QAInterfaceProps {
    onFirstQuestionAsked: (question: string) => void;
    currentQuestion: string;
}

interface Data {
    mainquestion?: string;
    section?: string;
    additionalInformation?: string;
    secondaryQuestion?: string;
    link?: string;
}

interface Answer {
    text: string;
    valid: boolean;
}

interface QAPair {
    question: string;
    answer: Answer;
    context?: { [key: string]: any };
    error: boolean;
    button: string;
}

const QAInterface1: FC<QAInterfaceProps> = ({ onFirstQuestionAsked, currentQuestion }) => {

    const [question, setQuestion] = useState<string>('');
    const [qaPairs, setQAPairs] = useState<QAPair[]>([]);
    const [firstQuestionAsked, setFirstQuestionAsked] = useState<boolean>(currentQuestion !== '' ? true : false);
    // const qaContainerRef = useRef<HTMLDivElement | null>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [questionIndex, setQuestionIndex] = useState(100);
    const [isFullQuestionShown, setIsFullQuestionShown] = useState(false);


    const [selectedButton, setSelectedButton] = useState('Summarize');
    const [selectedOption, setSelectedOption] = useState('Text');

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const { currentUser } = useAuth();

    const additionalInformation = useRef({
        'text': '',
        'document': {
            '0': '',
            "1": '',
            "2": ''
        },
        'doc_id': {
            '0': '',
            "1": '',
            "2": ''
        },
        'url': ''
    });

    const buttonQuestions = {
        Summarize: 'Summarize the content into a paragraph',
        Translate: 'Please translate the material to {language} ',
        Impact: 'Please provide an impact statement for this material ',
        Action: 'What are the actions arising out of this material? ',
        FAQ: 'Please prepare Frequently Asked Questions  (FAQs) and responses for this material ',
        'Compare Documents': 'Please compare the documents for differences and similarities'
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).catch(() => {
            alert('Failed to copy.');
        });
    };

    const clearQuestion = () => {
        setQuestion('');
    }

    const handleDownload = async (question, answer, button, context, download = true) => {
        if (typeof answer !== 'object') {
            console.error('Invalid answer format. Expected an object.');
            return;
        }

        // Initialize jsPDF
        const doc = new jsPDF({
            orientation: "portrait",
            unit: "pt",
            format: "letter",
        });

        // Define margins and page dimensions
        const margin = 30;
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const contentWidth = pageWidth - 2 * margin;
        const lineHeight = 14;
        let yOffset = margin;

        // Add Title (Question)
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(16);
        const title = button.split('\n')[0];
        const titleWidth = doc.getTextWidth(title);
        doc.text(title, (pageWidth - titleWidth) / 2, yOffset);
        yOffset += lineHeight * 2;

        // Compile context and answer into content
        let markdownContent = "";

        markdownContent = `# Prompt: \n`;
        markdownContent += `**Question:** ${question}\n\n`;

        if (context) {
            if (context.document0) {
                markdownContent += `**Document 1:** ${(context.document0 instanceof File ? context.document0.name : context.document0)}\n\n\n`;
            } else if (context.document1 && context.document2) {
                markdownContent += `**Document 1:** ${(context.document1 instanceof File ? context.document1.name : context.document1)}\n\n`;
                markdownContent += `**Document 2:** ${(context.document2 instanceof File ? context.document2.name : context.document2)}\n\n\n`;
            } else if (context.doc_id0) {
                markdownContent += `**Document ID 1:** ${context.doc_id0}\n\n\n`;
            } else if (context.doc_id1 && context.doc_id2) {
                markdownContent += `**Document ID 1:** ${context.doc_id1}\n\n`;
                markdownContent += `**Document ID 2:** ${context.doc_id2}\n\n\n`;
            } else if (context.url) {
                markdownContent += `**URL:** ${context.url}\n\n\n`;
            } else if (context.text) {
                markdownContent += `**Content:**\n${context.text}\n\n\n`;
            }
        }

        markdownContent += `# Lexim Response:\n${answer?.text || "No Answer Provided"}\n`;

        // Convert markdown to HTML
        const htmlContent = await marked(markdownContent);

        // Parse HTML content
        const parser = new DOMParser();
        const parsedHTML = parser.parseFromString(htmlContent, "text/html");

        const renderElement = (element) => {
            if (yOffset + lineHeight > pageHeight - margin) {
                // Add a new page if content exceeds the current page
                doc.addPage();
                yOffset = margin;
            }

            switch (element.nodeName.toLowerCase()) {
                case "h1":
                    renderHeader(element, 14, 0);
                    break;
                case "h2":
                    renderHeader(element, 12, lineHeight * 0.5);
                    break;
                case "p":
                    renderParagraph(element, lineHeight);
                    break;
                case "ul":
                    renderList(element);
                    break;
                default:
                    // Render as normal text block
                    renderTextBlock(element, lineHeight);
                    break;
            }
        };

        // Helper functions for rendering
        const renderList = (element) => {
            doc.setFont("Helvetica", "normal");
            doc.setFontSize(10);
            element.querySelectorAll("li").forEach((li) => {
                const liText = li.textContent.trim();
                const liParts = liText.split(": "); // Split the text before and after the colon

                if (liParts.length > 1) {
                    // First part (before the colon) should be bolded
                    const boldText = liParts[0] + ": "; // The part before the colon
                    let regularText = liParts[1]; // The part after the colon

                    // Add the bullet point before the bolded text
                    const bulletText = `• ${boldText}`;
                    if (!regularText.startsWith(" ")) {
                        regularText = " " + regularText;
                    }
                    const fullText = bulletText + " " + regularText; // Full text with bullet and space after colon

                    // Split the full text to respect width and avoid overflow
                    const fullTextLines = doc.splitTextToSize(fullText, contentWidth - 10);

                    // Render each line of the full text
                    fullTextLines.forEach((line, index) => {
                        if (yOffset + lineHeight > pageHeight - margin) {
                            doc.addPage();
                            yOffset = margin;
                        }

                        // If it's the first line, render the bold part and regular part
                        if (index === 0) {
                            // Render the bold part (before the colon)
                            doc.setFont("Helvetica", "bold");
                            const splitIndex = line.indexOf(": "); // Find where the colon is to separate bold and regular text
                            const boldPart = line.substring(0, splitIndex + 2); // Include the colon in bold
                            doc.text(boldPart, margin + 10, yOffset);

                            // Then, render the regular part (after the colon)
                            doc.setFont("Helvetica", "normal");
                            const regularPart = line.substring(splitIndex + 2); // Everything after the colon
                            doc.text(regularPart, margin + 10 + doc.getTextWidth(boldPart), yOffset); // Ensure the regular part follows the bold part
                        } else {
                            // For wrapped lines, just add the regular part
                            doc.setFont("Helvetica", "normal");
                            doc.text(line, margin + 10, yOffset);
                        }

                        yOffset += lineHeight;
                    });
                }
                else {
                    // Regular list item without a colon
                    const bulletText = `• ${liText}`;
                    const lines = doc.splitTextToSize(bulletText, contentWidth);
                    lines.forEach((line) => {
                        if (yOffset + lineHeight > pageHeight - margin) {
                            doc.addPage();
                            yOffset = margin;
                        }
                        doc.text(line, margin + 10, yOffset);
                        yOffset += lineHeight;
                    });
                }
            });
            //yOffset += lineHeight * 0.5; // Extra space after list
        };

        const renderParagraph = (element, lineHeight) => {
            doc.setFontSize(11);
            let xOffset = margin; // Left margin for text

            // Iterate through the child nodes of the <p> element
            element.childNodes.forEach((child) => {
                if (child.nodeType === Node.TEXT_NODE) {
                    // Normal text node: directly append it as regular text
                    const text = child.textContent.trim();
                    if (text) {
                        doc.setFont("Helvetica", "normal");
                        const lines = doc.splitTextToSize(text, contentWidth);
                        lines.forEach((line) => {
                            if (yOffset + lineHeight > pageHeight - margin) {
                                doc.addPage();
                                yOffset = margin;
                            }
                            doc.text(line, xOffset, yOffset);
                            yOffset += lineHeight;
                        });
                    }
                } else if (child.nodeType === Node.ELEMENT_NODE) {
                    if (child.nodeName.toLowerCase() === "strong") {
                        // Bold text: handle it separately inside <strong> tags
                        const boldText = child.textContent.trim();
                        if (boldText) {
                            doc.setFont("Helvetica", "bold"); // Apply bold font
                            const lines = doc.splitTextToSize(boldText, contentWidth);
                            lines.forEach((line) => {
                                if (yOffset + lineHeight > pageHeight - margin) {
                                    doc.addPage();
                                    yOffset = margin;
                                }
                                doc.text(line, xOffset, yOffset);
                                yOffset += lineHeight;
                            });
                        }
                    }
                }
            });

            //yOffset += lineHeight * 0.5; // Extra space after the paragraph
        };

        const renderTextBlock = (element, lineHeight) => {
            doc.setFont("Helvetica", "normal");
            doc.setFontSize(10);

            let xOffset = margin; // Start at the left margin
            let currentLine = ""; // Variable to store the current line content

            // Handle inline bolding and normal text within the text block
            element.childNodes.forEach((child) => {
                if (child.nodeType === Node.TEXT_NODE) {
                    // Normal text node: append to current line
                    const text = child.textContent.trim();
                    currentLine += text + " "; // Add normal text with a space
                } else if (child.nodeType === Node.ELEMENT_NODE) {
                    if (child.nodeName.toLowerCase() === "strong" || child.nodeName.toLowerCase() === "b") {
                        // Bold text: apply bold font
                        const boldText = child.textContent.trim();
                        doc.setFont("Helvetica", "bold");
                        currentLine += boldText + " "; // Add bold text with a space
                        doc.setFont("Helvetica", "normal"); // Reset font to normal after the bold text
                    } else {
                        // Regular text: append as normal text
                        const regularText = child.textContent.trim();
                        currentLine += regularText + " ";
                    }
                }
            });

            // Now that we have the full line, we split it into lines that fit the content width
            const splitLines = doc.splitTextToSize(currentLine, contentWidth);

            // Render the lines
            splitLines.forEach((line) => {
                if (yOffset + lineHeight > pageHeight - margin) {
                    doc.addPage();
                    yOffset = margin;
                }
                doc.text(line, xOffset, yOffset);
                yOffset += lineHeight;
            });
        };

        // Handling a header that is bold
        const renderHeader = (element, fontSize, extraSpace) => {
            yOffset += 5;
            doc.setFont("Helvetica", "bold");
            doc.setFontSize(fontSize);
            const lines = doc.splitTextToSize(element.textContent, contentWidth);
            lines.forEach((line) => {
                if (yOffset + lineHeight > pageHeight - margin) {
                    doc.addPage();
                    yOffset = margin;
                }
                doc.text(line, margin, yOffset);
                yOffset += lineHeight;
            });
            yOffset += extraSpace; // Add extra space after header
        };

        // Render each child element of the parsed HTML
        parsedHTML.body.childNodes.forEach((child) => {
            renderElement(child);
        });

        // Generate file name
        const date = new Date().toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }).replace(/\//g, '-').replace(/:/g, '-');

        const fileName = `${button.replace(/\s/g, '_')} ${date}.pdf`;

        if (download) {
            doc.save(fileName);
        } else {
            const pdfBlob = doc.output('blob');
            return [pdfBlob, fileName];
        }
    };


    const handleSaveFolder = async (moveFile: any) => {
        const result = await handleDownload(moveFile.question, moveFile.answer, moveFile.button, moveFile.context, false);
        if (!result) {
            console.error('Failed to generate PDF blob.');
            return;
        }
        const [pdfBlob, fileName] = result;

        if (!(pdfBlob instanceof Blob)) {
            console.error('Failed to generate a valid Blob object.');
            return;
        }

        // Check PDF size before upload
        const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB
        if (pdfBlob.size > MAX_FILE_SIZE) {
            alert('File size is greater than 20 MB');
            return;
        }

        const formData = new FormData();
        // const uploadFormData = new FormData();
        if (pdfBlob instanceof Blob && typeof fileName === 'string') {
            formData.append('file', pdfBlob, fileName);
            // uploadFormData.append('files', pdfBlob, fileName);

        } else {
            console.error('Invalid Blob or filename');
        }
        formData.append('title', fileName);
        formData.append('description', '');
        formData.append('genai_requested', 'False');
        formData.append('translation_requested', 'False');
        formData.append('video_training_requested', 'False');
        formData.append('translate_from', '');
        formData.append('translate_to', '');
        // formData.append('folder_id', `${folderID}`);
        // formData.append('folder_s3_link', folder_s3_link)

        // uploadFormData.append('user_id', String(currentUser?.id));
        // uploadFormData.append('vault_s3_link', folder_s3_link);

        // const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/upload-file`, {
        //     method: 'POST',
        //     body: uploadFormData
        // })

        // const response = await fetch(`https://deepapi.lexim.ai/upload-file`, {
        //     method: 'POST',
        //     body: uploadFormData
        // })

        // if (response.ok) {

        //     const data = await response.json();

        //     const uploadedResults = JSON.parse(data[0].body);
        //     if (uploadedResults) {
        //         uploadedResults.forEach(result => {
        //             formData.append('mv_document_id_external', result.mv_document_id_external);
        //             formData.append('s3_link', result.file_path);
        //         });
        //     } else {
        //         console.error('Uploaded results are missing or undefined');
        //     }

        axios.post(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, formData)
            .then(response => {
                setIsLoading(false);
                setMessage('Document uploaded successfully!')
                setShowSuccessModal(true);
                axios.patch(`/reg/v1/user/myresearch`, {
                    question: moveFile.question,
                    saved_to_cabinet: true,
                })
                    .catch(error => {
                        console.error('There was an error!', error);
                    }
                    )
            })
            .catch(error => {
                setIsLoading(false);
                if (error.response && error.response.status === 413) {
                    setMessage(`Failed to upload document due to size limit: File size is greater than 20 MB`);
                    setShowSuccessModal(true);
                } else {
                    setMessage(`Failed to upload documents: \n ${error.response?.data?.message || 'Unknown error'}`)
                    const failedMessages = error.response?.data?.filter((item: any) => item.status === "failed").map((item: any) => item.message).join('\n');
                    setMessage(`Failed to upload documents: \n ${failedMessages || 'Unknown error'}`);
                    setShowSuccessModal(true);
                }
                console.error('There was an error!', error);

            });
        // }
        // else {
        //     console.error('Failed to upload documents:', response);
        //     setMessage('Failed to upload documents: \n' + response.statusText);
        //     setShowSuccessModal(true);
        // }
    }


    useEffect(() => {
        setQuestion(buttonQuestions[selectedButton])
    }, [selectedButton]);

    const askQuestion = async () => {
        const framedQuestion = question;
        //console.log(question)
        // additionalInformation.current['text'] = question.split('\n').slice(1).join('\n');

        //console.log(additionalInformation)


        const context = {
            'text': additionalInformation.current['text'],
            'document0': additionalInformation.current['document'][0],
            'document1': additionalInformation.current['document'][1],
            'document2': additionalInformation.current['document'][2],
            'doc_id0': additionalInformation.current['doc_id'][0],
            'doc_id1': additionalInformation.current['doc_id'][1],
            'doc_id2': additionalInformation.current['doc_id'][2],
            'url': additionalInformation.current['url']
        }

        setQAPairs(prevQAPairs => [
            ...prevQAPairs,
            {
                question: framedQuestion,
                answer: { text: 'Thinking ...', valid: true },
                context: context,
                error: false,
                button: selectedButton
            }
        ]);

        try {
            // const API_URL = `http://127.0.0.1:8000/process-query`;

            const API_URL = `${process.env.REACT_APP_DEEP_API_URL}/process-query`;

            // const API_URL = `https://deepapi.lexim.ai/process-query`;

            let url1 = '';
            let url2 = '';
            let url3 = ''

            if (additionalInformation.current['doc_id'][0] !== '') {
                let response1 = await axios.get(`/reg/v1/document/all_documents/viewpdf/${additionalInformation.current['doc_id'][0]}`);
                url1 = response1.data.url;
            }

            if (additionalInformation.current['doc_id'][1] !== '') {
                let response2 = await axios.get(`/reg/v1/document/all_documents/viewpdf/${additionalInformation.current['doc_id'][1]}`);
                url2 = response2.data.url;
            }

            if (additionalInformation.current['doc_id'][2] !== '') {
                let response3 = await axios.get(`/reg/v1/document/all_documents/viewpdf/${additionalInformation.current['doc_id'][2]}`);
                url3 = response3.data.url;
            }
            const newQuestion = question + " Only use content under the Department of Health and Human Services and the Food and Drug Administration sections.";
            const payLoad = new FormData();
            payLoad.append('question', question && question.trim() !== '' ? newQuestion : ' ');
            payLoad.append('text', additionalInformation.current['text']);
            payLoad.append('additionalInformationFile1', additionalInformation.current['document'][0]);
            payLoad.append('additionalInformationFile2', additionalInformation.current['document'][1]);
            payLoad.append('additionalInformationFile3', additionalInformation.current['document'][2]);
            payLoad.append('document_ID1', url1);
            payLoad.append('document_ID2', url2);
            payLoad.append('document_ID3', url3);
            payLoad.append('additionalInformationLink', additionalInformation.current['url']);

            const response = await fetch(API_URL, {
                method: 'POST',
                body: payLoad,
            });

            if (!response.body) {
                throw new Error("No response body available for streaming");
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let receivedText = "";

            setQAPairs(prevQAPairs => {
                const updatedQAPairs = [...prevQAPairs];
                updatedQAPairs[updatedQAPairs.length - 1] = {
                    question: framedQuestion,
                    answer: {
                        text: "",
                        valid: true
                    },
                    context: context,
                    error: false,
                    button: selectedButton
                };
                return updatedQAPairs;
            });

            // Read and process the stream
            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                // Decode and append the received text
                receivedText += decoder.decode(value, { stream: true });

                // Update the last QAPair with the streamed characters
                setQAPairs(prevQAPairs => {
                    const updatedQAPairs = [...prevQAPairs];
                    updatedQAPairs[updatedQAPairs.length - 1].answer.text = receivedText;
                    return updatedQAPairs;
                });
            }

            const documents: any[] = [];

            // Add additionalInformation documents
            Object.values(additionalInformation.current['document']).forEach((doc: any) => {
                if (doc && doc instanceof File && doc.name !== '') {
                    documents.push(doc.name);
                }
            });

            Object.values(additionalInformation.current['doc_id']).forEach((doc: any) => {
                if (doc && doc !== '') {
                    documents.push(doc);
                }
            });

            // Prepare the API call
            axios.post(`/reg/v1/user/myresearch`, {
                question: question,
                documents: documents,
                saved_to_cabinet: false,
            })
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });


        } catch (error) {
            setQAPairs(prevQAPairs => {
                const updatedQAPairs = [...prevQAPairs];
                updatedQAPairs[updatedQAPairs.length - 1] = {
                    question: framedQuestion,
                    answer: {
                        text: "Content can not be parsed",
                        valid: true
                    },
                    context: context,
                    error: false,
                    button: selectedButton
                };
                return updatedQAPairs;
            });
        }

        //setQuestion(buttonQuestions[selectedButton]);
    };

    const toggleQuestionView = () => {
        setIsFullQuestionShown(!isFullQuestionShown);
    };

    const clearQuestions = () => {
        setQAPairs([]);
        setQuestion(buttonQuestions[selectedButton]);
        additionalInformation.current = {

            'text': '',
            'document': {
                '0': '',
                "1": '',
                "2": ''
            },
            'doc_id': {
                '0': '',
                "1": '',
                "2": ''
            },
            'url': ''
        }


    }

    const truncateByWords = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length <= wordLimit) return text;

        // Join the first `wordLimit` words
        let truncated = words.slice(0, wordLimit).join(' ');

        // Remove trailing punctuation at the end of the string, if present
        truncated = truncated.replace(/[.,;!?]$/, '');

        return truncated + '...';
    };


    return (
        <div className="card-body" style={{ maxHeight: '100vh', width: '100%', position: 'relative' }}>
            <MyResearchButtons
                selectedButton={selectedButton}
                setSelectedButton={setSelectedButton}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                additionalInformation={additionalInformation}
                askQuestion={askQuestion}
                clearQuestions={clearQuestions}
            />

            <div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex position-relative" style={{ flex: 1 }}>
                        <textarea
                            className="form-control pe-16 text-dark"
                            placeholder={firstQuestionAsked ? "Please ask a follow-up question" : "Please ask a research question"}
                            value={question}
                            rows={3}
                            onChange={(e) => setQuestion(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    if (e.ctrlKey) {
                                        // Trigger askQuestion on Ctrl+Enter
                                        if (question.trim() !== '') {
                                            askQuestion();
                                        }
                                        e.preventDefault(); // Prevent new line for Ctrl+Enter
                                    }
                                }
                            }}
                            style={{ paddingRight: '50px', resize: 'none' }}
                            maxLength={5000}
                        />
                        {question.trim() !== '' && (
                            <>
                                <div>
                                    <button onClick={clearQuestion} className="position-absolute end-0 top-50 translate-bottom-y form-control-button align-items-center justify-content-center" style={{ background: 'none', border: 'none' }}>
                                        <KTIcon
                                            iconName="cross"
                                            iconType="duotone"
                                            className="text-dark fs-1 mx-2 mt-2 me-12 icon-color"
                                        />
                                    </button>
                                    <div style={{ borderLeft: '1px solid #d3d3d3', height: '24px', position: 'absolute', top: '50%', transform: 'translateY(20%)', right: '36px' }}></div>
                                </div>

                                <button
                                    onClick={() => askQuestion()}
                                    className="position-absolute end-0 top-50 translate-bottom-y form-control-button align-items-center justify-content-center"
                                    style={{ background: 'none', border: 'none' }}
                                    disabled={question.trim() === ''}
                                >
                                    <KTIcon
                                        iconName="arrow-right"
                                        iconType="duotone"
                                        className="text-dark fs-1 mx-2 mt-2 me-1 icon-color"
                                    />
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Scrollable container */}
            <div
                className="mb-5 mt-8"
                style={{
                    maxHeight: 'calc(70vh - 100px)',
                    overflowY: 'auto',
                    position: 'relative',
                }}

            >
                {qaPairs.slice(0).reverse().map((qaPair, index) => (
                    <div key={index} className={`d-flex flex-column ${index !== 0 ? 'mt-3' : ''}`}>
                        {/* Question Box */}
                        <div className={`d-flex justify-content-start ${index !== 0 ? 'mt-2' : ''} mb-3`}>
                            <div className={`p-7 ps-11 fs-6 text-gray-900 fw-medium rounded bg-light-primary`} style={{ width: '100%', wordWrap: 'break-word' }}>
                                <h1 className="text-black fw-bold mb-5" style={{ fontSize: '1.25rem' }}>
                                    {isFullQuestionShown
                                        ? <ReactMarkdown>{qaPair.question.replace(/\n/g, '\n\n')}</ReactMarkdown>
                                        : <ReactMarkdown>{qaPair.question.slice(0, questionIndex).replace(/\n/g, '\n\n')}</ReactMarkdown>
                                    }
                                    {qaPair.question.split(' ').length > questionIndex && (
                                        <div>
                                            <div className="text-dark">
                                                {isFullQuestionShown
                                                    ? qaPair.question
                                                    : truncateByWords(qaPair.question, questionIndex)}
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    onClick={toggleQuestionView}
                                                    onMouseEnter={() => setIsHovered(true)}
                                                    onMouseLeave={() => setIsHovered(false)}
                                                    className={`btn btn-link p-0 me-5 mt-2 text-primary`}
                                                    style={{
                                                        fontSize: '1.1rem',
                                                        fontWeight: 'bold',
                                                        textDecoration: isHovered ? 'underline' : 'none',
                                                    }}
                                                >
                                                    {isFullQuestionShown ? 'Show Less' : 'Show More'}
                                                </button>
                                            </div>
                                        </div>
                                    )}


                                    {qaPair.context && (
                                        <>
                                            {qaPair.context.text && (
                                                <div>
                                                    <div className="text-dark fw-bold mb-2">Content:</div>
                                                    <div className="fw-normal">
                                                        {isExpanded
                                                            ? <ReactMarkdown>{qaPair.context.text.replace(/\n/g, '\n\n')}</ReactMarkdown>
                                                            : <ReactMarkdown>{truncateByWords(qaPair.context.text, questionIndex).replace(/\n/g, '\n\n')}</ReactMarkdown>
                                                        }
                                                        {/* Show the "More" button if the content is longer than the truncated version */}
                                                        {qaPair.context.text.split(' ').length > questionIndex && (
                                                            <div className="d-flex justify-content-end">
                                                                <button
                                                                    onClick={() => setIsExpanded(!isExpanded)}
                                                                    onMouseEnter={() => setIsHovered(true)}
                                                                    onMouseLeave={() => setIsHovered(false)}
                                                                    className={`btn btn-link p-0 me-5 xmt-2 text-primary`}
                                                                    style={{
                                                                        fontSize: '1.1rem',
                                                                        fontWeight: 'bold',
                                                                        textDecoration: isHovered ? 'underline' : 'none',
                                                                    }}
                                                                >
                                                                    {isExpanded ? 'Show Less' : 'Show More'}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {[qaPair.context.document0, qaPair.context.document1, qaPair.context.document2]
                                                .filter(Boolean)
                                                .map((document, index) => (
                                                    <div key={index} className="d-flex justify-content-start mb-2">
                                                        <span className="text-dark fw-bold">Document {index + 1}: </span>
                                                        <span className="fw-normal ms-1">
                                                            {document instanceof File ? document.name : document}
                                                        </span>
                                                    </div>
                                                ))}
                                            {/* {[qaPair.context.doc_id0, qaPair.context.doc_id1, qaPair.context.doc_id2]
                                                .filter(Boolean)
                                                .map((document, index) => (
                                                    <div key={index} className="d-flex justify-content-start mb-2">
                                                        <span className="text-dark fw-bold">Document {index + 1}: </span>
                                                        <span className="fw-normal ms-1">
                                                            {document}
                                                        </span>
                                                    </div>
                                                ))} */}
                                            {(qaPair.context.url !== '') && (
                                                <div className="d-flex justify-content-start mb-2">
                                                    <span className="text-dark fw-bold">URL: </span>
                                                    <span className="fw-normal ms-1">{qaPair.context.url}</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </h1>
                            </div>
                        </div>

                        {/* Answer Box */}
                        <div className={`d-flex justify-content-start mb-5`}>
                            <div className={`p-7 ps-11 fs-6 text-gray-900 fw-medium rounded ${qaPair.error ? 'bg-danger text-dark' : 'bg-light-info'}`} style={{ width: '100%', wordWrap: 'break-word' }}>
                                {qaPair.answer.text === 'Thinking ...' ? (
                                    <div className="d-flex justify-content-start">
                                        {[...Array(4)].map((_, idx) => (
                                            <div key={idx} className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <div className="d-flex justify-content-end" style={{ cursor: 'pointer' }}>
                                            <div className="pe-4">
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Copy Message</Tooltip>}>
                                                    <div
                                                        onClick={() => copyToClipboard(qaPair.answer.text)}
                                                        title="Copy to clipboard"
                                                    >
                                                        <KTIcon iconName="copy" iconType="duotone" className="text-primary fw-bold fs-1" />
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="pe-4">
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Save to Cabinet</Tooltip>}>
                                                    <i
                                                        className="bi bi-floppy text-primary text-bold fs-1"
                                                        onClick={() => handleSaveFolder({
                                                            parent_id: 0,
                                                            name: `${qaPair.button.replace(/\s/g, '_')} ${new Date().toLocaleDateString('en-US', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year: 'numeric',
                                                            }).replace(/\//g, '-')} ${new Date().toLocaleTimeString('en-US', {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                second: '2-digit',
                                                                hour12: true,
                                                            }).replace(/:/g, '-')}.pdf`,
                                                            question: qaPair.question,
                                                            answer: qaPair.answer,
                                                            context: qaPair.context,
                                                            button: qaPair.button,
                                                            type: 'file'
                                                        })}
                                                    ></i>
                                                </OverlayTrigger>
                                                {
                                                    showSaveModal && (
                                                        <MoveFolderModal
                                                            showModal={showSaveModal}
                                                            setShowModal={setShowSaveModal}
                                                            moveFile={{
                                                                parent_id: 0,
                                                                name: `${qaPair.button.replace(/\s/g, '_')} ${new Date().toLocaleDateString('en-US', {
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                    year: 'numeric',
                                                                }).replace(/\//g, '-')} ${new Date().toLocaleTimeString('en-US', {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    second: '2-digit',
                                                                    hour12: true,
                                                                }).replace(/:/g, '-')}.pdf`,
                                                                question: qaPair.question,
                                                                answer: qaPair.answer,
                                                                context: qaPair.context,
                                                                button: qaPair.button,
                                                                type: 'file'
                                                            }}
                                                            handleMoveFolder={handleSaveFolder}
                                                            isLoading={isLoading}
                                                            fromWorkbench={true}

                                                        />
                                                    )
                                                }
                                                <FolderSuccessModal
                                                    showToast={showSuccessModal}
                                                    message={message}
                                                    onClose={() => setShowSuccessModal(false)}
                                                />
                                            </div>
                                            <div>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Download message</Tooltip>}>
                                                    <i
                                                        className="bi bi-download text-primary text-bold fs-1 me-1"
                                                        onClick={() => handleDownload(qaPair.question, qaPair.answer, qaPair.button, qaPair.context)}
                                                    ></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>

                                        <div>
                                            <h3 className="fw-bold mb-3 text-dark">Lexim Response:</h3>
                                            <ReactMarkdown className="mb-5">{qaPair.answer.text}</ReactMarkdown>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div >
    );
};


export { QAInterface1 };